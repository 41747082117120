<template>
	
	<div class="cont" id="p07">
		<div class="page-title">{{ program.name }}</div>					
									
		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="상호명, 대표자명, ID를 입력하세요."
								v-model="search.keyword"
							>
						</div>
					</div>
					<div class="c-item">
						<div class="title">상태</div>
						<div class="content">
							<select
								v-model="search.status"
								@change="getData"
							>
								<option value="">전체</option>
								<option 
									v-for="(stat, index) in status"
									:key="index"
									:value="stat.code"
								>{{ stat.codeName }}
								</option>
							</select>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<a ><div class="btn-excel">
						<vue-excel-xlsx
							:data="excel_items"
							:columns="columns"
							:filename="program.name"
							:sheetname="program.name"
						>
						엑셀 다운로드
						</vue-excel-xlsx>
					</div></a>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="cont-btn">
				<div class="btn-target_toggle btn-reseller"
					v-if="user.basic.level == '1'"
					:class="{ active: active.reseller }"
					@click=" active.reseller ? active.reseller = false : active.reseller = true"
					>
					<i :class="active.reseller ? 'xi-check' : 'xi-checkbox-blank'"></i>
					리셀러
				</div>
						
				<div class="btn-target_toggle btn-dealer"
					v-if="user.basic.level == '1' || user.basic.level == '2'"
					:class="{ active: active.dealer }"
					@click=" active.dealer ? active.dealer = false : active.dealer = true"
					>
					<i :class="active.dealer ? 'xi-check' : 'xi-checkbox-blank'"></i>
					딜러
				</div>
			</div>
			<div class="table type10">
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">사업자 구분</div>
						<div class="td">대리점명</div>
						<div class="td">대표자</div>
						<div class="td">연락처</div>
						<div class="td">등록일</div>
						<div class="td">계정 상태</div>
						<div class="td">상세</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in item_list"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.companyTypeName }}</div>
						<div class="td">[{{ item.agentTypeName }}] {{ item.businessName }}</div>
						<div class="td">{{ item.ceoName }}</div>
						<div class="td">{{ item.phoneNumber | makePhoneNumber }}</div>
						<div class="td">{{ item.joinDate | transDate }}</div>
						<div class="td">  <!-- display block = class "active" -->
							<div class="tp01 active"
								v-if="item.status == 'Normal'"
							><a >{{ item.statusName }} <i class="xi-play" v-show="false"></i></a></div>
							<div class="tp02 active"
								v-if="item.status == 'Pause'"
							><a >{{ item.statusName }} <i class="xi-play" v-show="false"></i></a></div>
							<div class="tp03 active"
								v-if="item.status == 'Termination'"
							><a >{{ item.statusName }} <i class="xi-play" v-show="false"></i></a></div>
						</div>
						<div class="td"><router-link :to="{ name: 'AgentSaelsDetail', params: {index: item.agentSeq }}" >상세보기</router-link></div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>

				<div class="text-center mt-10">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>
		
		<ConfirmList 
			:overlay="overlay"
			@click="close"
			v-if="false"
		/>
		
		
		
	</div>
</template>

<script>

	import ConfirmList from '@/view/Franchisee/ConfirmList'
	import Pagination from '@/components/Pagination'
	import { Axios } from '@/resources/axios/axios'
	import { filter } from '@/resources/filter/filter'
	
	export default {
		name: 'AgentList'
		,props: ['user']
		,components: { ConfirmList, Pagination }
		,data: function(){
			return {
				program: {
					name: '하위 대리점 목록'
				}
				,items: []
				,search: {
					inquiryEndDate: ''
					,inquiryStartDate: ''
					,keyword: ''
					,status: ''
					,limitApplyStatus: ''
					,page: 1
					,list_cnt: 10
					,page_cnt: 10
					,total_count: 1
					,link:'/Agent/List/'
					,agentType: ''
				}
				,excel_items: [
				]
				,columns: [
					{ label: 'No', field: 'rowNumber'}
					,{ label: '사업자구분', field: 'companyTypeName'}
					,{ label: '대리점명', field: 'agentTypeName'}
					,{ label: '대표자', field: 'ceoName'}
					,{ label: '연락처', field: 'phoneNumber'}
					,{ label: '인증', field: 'certificationFee'}
					,{ label: '수기', field: 'nonCertificationFee'}
					,{ label: 'OFF', field: 'offLineFee'}
					,{ label: '계정 상태', field: 'statusName'}
					,{ label: '등록일', field: 'joinDate'}
				]
				,status: [
					{ code: 'Normal', codeName: '정상사용'}
					,{ code: 'Pause', codeName: '사용중지'}
					,{ code: 'Termination', codeName: '해지'}
				]
				,overlay: false
				,modal: false
				,active: {
					reseller: true
					,dealer: true
				}
			}
		}
		,computed: {
			
			item_list: function(){
				return this.items.filter(function(item){
					item.agentMarginRate = ['', '', '']
					return item
				})
			}
		}
		,filter: filter
		,methods: {
			overlayView: function(){
				this.$emit('setOverlay')
				this.overlay = true
			}
			,close: function(){
				this.$emit('setOverlay')
				this.overlay = false
			}
			,getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sales'
						,authorize: true
						,data: this.search
					})
					
					if(result.success){
						this.items = result.data.content.content
						await this.excel()
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message})
					}
				}catch(E){
					console.log(E)
					
					this.$emit('setNotify', { type: 'error', message: E.status})
				}
			}
			,clear: function(){
				this.search.keyword = ''
				this.search.status = ''
			}
			,excel: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/sales/download'
						,authorize: true
					})
					if(result.success){
						this.excel_items = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: function(){
			this.$emit('setProgram', this.program)
		}
		,watch: {
			'search.page': {
				immediate: true
				,handler: function(){
//					this.$router.push({ name: 'AgentSalesList', params: { index: this.index }})
					this.getData()
				}
			}
			,active: {
				deep: true
				,handler: function(call){
					if(call.reseller && !call.dealer){
						this.search.agentType = 'resales'
					}else if(!call.reseller && call.dealer){
						this.search.agentType = 'dealer'
					}else{
						this.search.agentType = ''
					}
					
					this.getData()
				}
			}
		}
	}
</script>





